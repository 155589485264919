import React from 'react'
import SessionStore from '../api/stores/SessionStore'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import ForgotPassword from '../components/user/ForgotPassword'
import Spinner from '../components/common/Spinner'
import QueryParamsFunctions from '../api/constants/QueryParamsFunctions'
import EdgePipelineLander from '../components/user/EdgePipelineLander'
import UserActionCreators from '../api/actions/UserActionCreators'
import AppConstants from '../api/constants/AppConstants'
import AuctionCentralLander from '../components/user/AuctionCentralLander'
import XciralLander from '../components/user/XciraLander'
import DarwinLander from '../components/user/DarwinLander'
import ApolloPromotionalLanderStatic from './ApolloPromotionalLanderStatic'

const EDGE_PARTNER_PID = 'edge'
const AUCTION_CENTRAL_PARTNER_PID = 'auctioncentral'
const XCIRA_PARTNER_PID = 'xcira'
const DARWIN_PARTNER_PID = 'darwin'
const APOLLO_PROMOTIONAL_PARAM = 'campaign_emails_contacts_id'
const APLUS_LANDER_IS_STATIC = true

export default class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    loginFailed: false,
    loginIsLoading: false,
    isShowingForgotPassword: false,
    handlePartnerLoginDismissed: false,
    edgeAuthorizeURL: null,
    isAuthenticatingWithEdge: false,
    isLoadingApolloPromotionalUser: false,
    showStaticApolloPromotionalScreen: false
  }

  constructor() {
    super()
    const paramsObj = QueryParamsFunctions.toObject()

    this.partnerPID = null

    this.isApolloPromotionalUser = Boolean(paramsObj && paramsObj[APOLLO_PROMOTIONAL_PARAM])

    this.handlePartnerLogin = Boolean(paramsObj && paramsObj['pid'] && (paramsObj['pid'] === EDGE_PARTNER_PID || paramsObj['pid'] === AUCTION_CENTRAL_PARTNER_PID || paramsObj['pid'] === XCIRA_PARTNER_PID || paramsObj['pid'] === DARWIN_PARTNER_PID))

    if (this.isApolloPromotionalUser) {
      this.apolloPromotionalID = paramsObj[APOLLO_PROMOTIONAL_PARAM]
    } else if (this.handlePartnerLogin) {
      this.partnerPID = paramsObj['pid']
      global.isPartnerPreview = true
    }
  }

  componentDidMount() {
    SessionStore.on('user_login_failed', this.loginFailed)
    SessionStore.on('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)
    SessionStore.on('user_data_changed', this.userDidChange)

    if (this.isApolloPromotionalUser) {
      // Load details for the Apollo promotional user
      if (!APLUS_LANDER_IS_STATIC) {
        SessionActionCreators.loginWithApolloCampaignContactID(this.apolloPromotionalID, null)
        this.setState({ isLoadingApolloPromotionalUser: true })
      } else {
        this.setState({
          showStaticApolloPromotionalScreen: true,
          loginIsLoading: false
        })
      }
    } else if (this.handlePartnerLogin && this.partnerPID === EDGE_PARTNER_PID) {
      UserActionCreators.loadEdgeAuthorizeURL(window.location, '&attribution_code=edge_embed_1')
    } else if (!global.isExtensionMode) {
      UserActionCreators.loadEdgeAuthorizeURL(window.location)
    }

    const paramsObj = QueryParamsFunctions.toObject()
    if (Boolean((paramsObj && paramsObj['forgotPasswordCode']) || window.location.href.includes('/forgot-password'))) {
      this.setState({
        isShowingForgotPassword: true
      })
    }

  }

  componentWillUnmount() {
    SessionStore.removeListener('user_login_failed', this.loginFailed)
    SessionStore.removeListener('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)
    SessionStore.removeListener('user_data_changed', this.userDidChange)
  }

  edgeAuthorizeURLChanged = () => {
    this.setState({
      edgeAuthorizeURL: SessionStore.edgeAuthorizeURL
    })
  }

  loginFailed = () => {
    this.setState({
      loginFailed: true,
      loginIsLoading: false
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    let { email, password } = this.state
    this.setState({
      loginIsLoading: true,
      loginFailed: false
    })
    SessionActionCreators.submitLogin(email, password, 'web', global.appVersion)
  }

  userDidChange = () => {
    if (this.isApolloPromotionalUser || this.handlePartnerLogin) {
      if (SessionStore.isAuthenticated()) {
        this.isApolloPromotionalUser = false
        this.apolloPromotionalID = null
        window.location.reload()
      } else if (this.isApolloPromotionalUser){
        window.location.replace('https://getcarbly.com')
      }
    }
  }


  render() {
    const { email, password, loginIsLoading, loginFailed, isShowingForgotPassword, handlePartnerLoginDismissed, edgeAuthorizeURL, isAuthenticatingWithEdge, isLoadingApolloPromotionalUser, showStaticApolloPromotionalScreen } = this.state

    let statusText = ''

    if (loginFailed) { statusText = "Incorrect email address or password" }

    if (isLoadingApolloPromotionalUser) {
      return (
        <div className="body-content">
          <Spinner className="spinner-page" />
        </div>
      )
    }

    if (showStaticApolloPromotionalScreen) {
      return (
        <ApolloPromotionalLanderStatic apolloPromotionalID={this.apolloPromotionalID} />
      )
    }

    if (this.handlePartnerLogin && !handlePartnerLoginDismissed) {
      if (this.partnerPID === AUCTION_CENTRAL_PARTNER_PID) {
        return ( <AuctionCentralLander onClose={() => {
          this.setState({ handlePartnerLoginDismissed: true })
        }} /> )
      } else if (this.partnerPID === EDGE_PARTNER_PID) {
        return <EdgePipelineLander onClose={() => {
          this.setState({ handlePartnerLoginDismissed: true })
        }} />
      } else if (this.partnerPID === XCIRA_PARTNER_PID) {
        return <XciralLander onClose={() => {
          this.setState({ handlePartnerLoginDismissed: true })
        }} />
      } else if (this.partnerPID === DARWIN_PARTNER_PID) {
        return <DarwinLander onClose={() => {
          this.setState({ handlePartnerLoginDismissed: true })
        }} />
      }
      
    }

    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: null
    });
  

    return (
      <div className="login-c">
        <div className="login">
          <img src="/images/login-logo.png" className="login-logo" alt="Carbly" />

          {
            !isShowingForgotPassword &&
            <div>
              <div className="login-failed">{ statusText }</div>

              <form name="loginForm" onSubmit={this.onSubmit}>
                <div className="form-group-collection">

                  <input type="email" className="field" name="email" onChange={e => this.setState({email: e.target.value})} value={email} placeholder="Email address" autoComplete="username" autoFocus={true} />

                  <input type="password" className="field" name="password" onChange={e => this.setState({password: e.target.value})} value={password} placeholder="Password" autoComplete="current-password" />

                </div>

                <div className="login-submit-c">
                  {
                    loginIsLoading &&
                    <div className="login-spinner-c">
                      <Spinner className='spinner-medium' />
                    </div>
                  }

                  {
                    !loginIsLoading &&
                    <input type="submit" value="Login" className="capsule-button" />
                  }
                </div>
              </form>
              {
                edgeAuthorizeURL?.edge_authorize_url && !global.isExtensionMode && !isAuthenticatingWithEdge &&
                <a href={edgeAuthorizeURL.edge_authorize_url} onClick={() => this.setState({ isAuthenticatingWithEdge: true })} style={{display: 'block', marginTop: "20px"}}>Or, login with your {AppConstants.edgePipelineName} account</a>
              }
              {
                isAuthenticatingWithEdge &&
                <div style={{marginTop: '20px'}}>
                  <Spinner className='spinner-medium' />
                </div>
              }
              <div className="secondary-text login-secondary">
                <a href="#forgot-password" onClick={(e) => {e.preventDefault(); this.setState({ isShowingForgotPassword: !isShowingForgotPassword })}} style={{marginBottom: '20px'}}>{isShowingForgotPassword ? 'Back to login' : 'Forgot your password?'}</a>
                <a href="https://getcarbly.com/register/" target={this.props.newWindowLinks ? '_blank' : '_self'}>Don't have an account? Start your free trial</a>
                <a href="https://getcarbly.com" target={this.props.newWindowLinks ? '_blank' : '_self'}>More about Carbly VIN Scanner</a>
              </div>
            </div>
          }

          {
            isShowingForgotPassword === true &&
            <ForgotPassword onCancel={() => this.setState({ isShowingForgotPassword: false })} email={email} onComplete={(passedEmail) => this.setState({email: passedEmail, isShowingForgotPassword: false})}/>
          }
        </div>
      </div>
    )
  }
}
