import React from 'react'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'
import PartnerLanderRegistrationForm from './PartnerLanderRegistrationForm'
import SessionStore from '../../api/stores/SessionStore'

const REFERRER_CODE = 'darwin'
const DARWIN_NAME = 'Darwin'

export default class DarwinLander extends React.Component {
  
  state = {
    mobileIsShowingMore: false,
    originErrors: null
  }

  componentDidMount() {
    window._dcq = window._dcq || []
    window._dcs = window._dcs || {}
    window._dcs.account = '3319107'

    var dc = document.createElement('script')
    dc.type = 'text/javascript'
    dc.async = true
    dc.src = '//tag.getdrip.com/3319107.js'

    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(dc, s)

    SessionStore.on('register_partner_origin_errors', this.handleOriginErrors)
  }

  componentWillUnmount() {
    ValuationStore.removeListener('public_valuation_received', this.getValuation)
    SessionStore.removeListener('register_partner_origin_errors', this.handleOriginErrors)
  }

  handleOriginErrors = () => {
    this.setState({
      originErrors: SessionStore.registerPartnerOriginErrors
    })
  }


  render() {
    const { mobileIsShowingMore, originErrors } = this.state
    const todayDate = new Date()

    return (
      <div className="partner-lander-c">
        <div className="partner-header-bg" />
        <div className="partner-lander">
          <div className="partner-lander-static-screen">
            <a href="/images/web-sample-screen-full.jpg" target="_blank" rel="noreferrer"><img src="/images/web-sample-screen-full.jpg" alt="Sample Carbly screen" /></a>
          </div>

          <div className="partner-lander-overview">
            <div className="intro">
              <div className="header-logos">
                <img src="/images/login-logo.png" className="carbly-logo" alt="Carbly" />
              </div>

              <p>Our partnership allows you to view Carbly vehicle appraisal data directly from {DARWIN_NAME} listings. Check market values and set ceiling prices quickly and easily.</p>
            </div>

            <div className="partner-body">
              <div>
                <p><a href={`https://getcarbly.com/?r=${REFERRER_CODE}`} target="_blank">Carbly</a> is a modern vehicle appraisal and VIN Scanner that combines multiple data sources to give you the wholesale, retail, local market, and vehicle history information you need to make more money buying and selling cars.</p>



                <div className="provider-logos">
                  <img src="/images/provider-black_book.png" alt="Black Book" />
                  <img src="/images/provider-manheim.png" alt="Manheim Market Report" />
                  <img src="/images/provider-real_retail.png" alt="Real Retail" className="real-retail" />
                  <img src="/images/provider-nada.png" alt="NADA" />
                  <img src="/images/provider-jd-power.png" alt="J.D. Power" className="jd-power" />
                  <img src="/images/provider-carfax.png" alt="CARFAX" className="carfax" />
                  <img src="/images/provider-kbb.png" alt="Kelley Blue Book" />
                  <img src="/images/provider-live_local_market.png" alt="Live Local Market" className="live-local-market" />
                  <img src="/images/auctions-plus-logo.png" alt="Auctions Plus" className="live-local-market" />
                  <img src="/images/regional-reports-plus-logo.png" style={{width: '162px', height: '30px'}} />
                </div>

                {
                  !mobileIsShowingMore &&
                  <a href="#show-more" id="mobile-show-more" onClick={(e) => {e.preventDefault(); this.setState({ mobileIsShowingMore: true})}}>Learn more</a>
                }

                <ul id="mobile-more" className={mobileIsShowingMore ? 'mobile-showing-more' : ''}>
                  <li>Scan VINs to see <a href={`https://getcarbly.com/appraisals/?r=${REFERRER_CODE}`} target="_blank">appraisal data</a> instantly</li>
                  <li>Automatic trim and options selection</li>
                  <li>Best-in-class <a href={`https://getcarbly.com/vin-scanner/?r=${REFERRER_CODE}`} target="_blank">VIN scanner</a> reads barcodes, text, QR codes and handwriting</li>
                  <li>Free integration of existing {AppConstants.providerNames['carfax']} and {AppConstants.providerNames['autocheck']} accounts</li>
                  <li>Add <a href={`https://getcarbly.com/teams/?r=${REFERRER_CODE}`} target="_blank">team members to collaborate</a> with vehicle watchlists, notes, folders and photos</li>
                  <li>Available for iPhone, Android, desktops and laptops</li>
                  <li>Free no-risk 14 day trial</li>
                </ul>
              </div>

              {
                Boolean(originErrors) &&
                <div style={{color: 'red', margin: '10px 0', textAlign: 'center'}}>{originErrors.map((e) => {
                  return <div key={e}>{e}</div>
                })}</div>
              }

              <PartnerLanderRegistrationForm origin={REFERRER_CODE} onClose={() => {}} />

              <div style={{textAlign: 'center'}}>Have questions?  Give us a call at {AppConstants.phoneNumber}</div>


              <div className="login">
                Already have a Carbly account? <a href="#login" onClick={(e) => { e.preventDefault(); this.props.onClose();}}>Login</a>
              </div>

              <div className="video-thumbnail">
                <a href="https://www.youtube.com/watch?v=H2p6ivpsJtE" target="_blank" onClick={(e) => {
                  e.preventDefault();
                  platformFunctions.logAnalyticsEvent("Partner", "Preview Opened Video")
                  window.open('https://www.youtube.com/watch?v=H2p6ivpsJtE', '_blank');
                }}>
                  <img src="/images/lander-video-thumbnail.png" alt="Play video" />
                  <div>See Carbly in Action</div>
                </a>
              </div>
              

            </div>
          </div>

        </div>

        <div className="partner-footer-c">
          <div className="partner-footer">
            <div className="partner-footer-body">
              <img src="/images/carbly-logo-yellow.png" alt="Carbly" />
              <div>
                <h3>Contact us</h3>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  {AppConstants.phoneNumber}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                  </svg>
                  {AppConstants.supportEmailAddress}
                </div>

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                  </svg>
                  <a href={`https://getcarbly.com/?r=${REFERRER_CODE}`} target="_blank">getcarbly.com</a>
                </div>

              </div>
            </div>
            <div className="partner-legal">
              <div>Carbly&reg; is a registered trademark of Ambient Automotive, Inc</div>
              <div>Copyright &copy; {todayDate.getFullYear()} Ambient Automotive, Inc</div>
            </div>

          </div>
        </div>

        {
          platformFunctions.isProduction() &&
          <iframe src={`https://getcarbly.com/landers/edge-iframe/?utm_source=darwin&utm_medium=referral&utm_campaign=Darwin+Embed&r=${REFERRER_CODE}`} width={1} height={1} title="shell" />
        }
      </div>
    )
  }



}
